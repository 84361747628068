
<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>配件列表</el-breadcrumb-item>
      <el-breadcrumb-item>适配型号列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!--    内容开始-->
    <el-card class="box-card">
      <el-button type="primary" @click='opentan()'>添加信息</el-button>
      <!--    列表开始-->
      <el-table
        :data="tableData"
        stripe
        style="width: 100%">
        <el-table-column
          prop="e_name"
          label="适配型号名称"
        >
        </el-table-column>
        <el-table-column
          prop="type_name"
          label="所属类别"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="品牌"
        >
        </el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" icon="el-icon-edit" @click='editfun(scope.row)'></el-button>
            <el-button size="mini" type="success" icon="el-icon-view" @click='lookfun(scope.row.id)'></el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" @click='delfun(scope.row.id)'></el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--      分页-->
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
    <!--  弹窗内容-->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose">
      <el-form ref="dform" :model="dform" label-width="120px" :rules="drules">
        <el-form-item label="适配型号名称" prop="e_name">
          <el-input v-model="dform.e_name"></el-input>
        </el-form-item>
                <el-form-item label="所属类别" prop='type_id'>
                  <el-radio-group v-model="dform.type_id">
                    <el-radio :label="1">上装</el-radio>
                    <el-radio :label="2">底盘</el-radio>
                  </el-radio-group>
                </el-form-item>
        <el-form-item label="选择品牌" prop='type_id'>
          <el-select v-model="dform.brand_id" placeholder="请选择">
            <el-option
              v-for="item in optionsdata"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择配件" prop='type_id'>
          <el-button type="primary" @click="openpeijian()">选择配件</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="cancelfun()">取 消</el-button>
    <el-button v-if="tantype!=='look'" type="primary" @keyup.enter.native="addgoto()" @click="addgoto(tantype)">确 定</el-button>
  </span>
    </el-dialog>
<!--    分配配件-->
    <el-dialog
      title="分配配件"
      :visible.sync="peijiandialogVisible"
      width="50%"
       class='peitan'
      :before-close="peihandleClose">
      <div v-for='item in partsdata'  :key='item.id'>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-checkbox v-model="item.p_checked"  :label="item.id">{{ item.parts_name }}</el-checkbox>
          </el-col>
          <el-col :span="6">
           <el-input placeholder="数量" v-model="item.p_num"></el-input>
          </el-col>
          <el-col :span="6">
            <el-input placeholder="图纸编号" v-model="item.p_serial"></el-input>
          </el-col>

        </el-row>

      </div>

      <span slot="footer" class="dialog-footer">
    <el-button @click="peijiandialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="addpeijan()">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye'
import {
  AddEquipment,
  DelEquipment,
  EditEquipment, GetBraandsList,
  GetEquipmentList,
  GetOneEquipment, GetPartsList
} from '../../../api/vehicle/autoconfigure'

export default {
  components: {
    Fenye
  },
  data() {
    return {
      loading: false,
      dialogVisible: false, // 弹窗控制
      dform: {
        e_name: '',
        type_id: 1,
        brand_id: '', // 品牌ID
        parts_id: []
      },
      peijiandialogVisible: false,
      // 选项
      optionsdata: [],
      // 弹窗
      // 按钮的状态
      tantype: 'add',
      // 验证规则
      drules: {
        e_name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ]

      },
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      // 列表数据
      tableData: [],
      // 配件列表
      partsdata: []
    }
  },
  mounted() {
    this.getequipmentlistfun()
    this.getpartslistfun()
  },
  methods: {
    addpeijan() { //选中的配件进行赋值
      this.peijiandialogVisible = false
      this.dform.parts_id = this.partsdata.filter(item => {
        if (item.p_checked) {
          return item
        }
      })
    },
    bianzhi(newSize) { // 分页值变化
      this.pageData.size = newSize
      this.getequipmentlistfun()
    },
    bianye(newPape) { // 分页页数变化
      this.pageData.page = newPape
      this.getequipmentlistfun()
    },
    editfun(ev) { // 打开编辑
      // 赋值
      this.dform = JSON.parse(JSON.stringify(ev))
      // 修改属性为修改
      this.tantype = 'edit'
      // 打开弹窗
      this.opentan()
    },
    lookfun(ld) { // 打开查看
      console.log(ld)
      this.oneequipmentfun(ld)
      // 修改属性为修改
      this.tantype = 'look'
      // 打开弹窗
      this.opentan()
    },
    delfun(id) { // 删除
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delequipmentfun(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 打开弹窗
    openpeijian() {
      this.peijiandialogVisible = true
    },
    opentan() {
      this.dialogVisible = true
      this.getbrandlistfun()
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        this.getpartslistfun(query) // 方法
      } else {
        this.options = []
      }
    },
    // 确定添加
    addgoto(type) {
      switch (type) {
        case 'add':
          console.log(type)
          this.$refs['dform'].validate(v => {
            if (v) {
              this.addequipmentfun(this.dform)
            }
          })
          break
        case 'edit':
          this.$refs['dform'].validate(v => {
            if (v) {
              this.editequipmentfun(this.dform)
            }
          })
          console.log(type)
          break
      }
    },
    // 取消
    cancelfun() {
      this.handleClose()
    },
    peihandleClose() {
      this.peijiandialogVisible = false
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
      this.dform = {}
      this.tantype = 'add'
    },
    // 请求区ლ(´ڡ`ლ)
    // 获取品牌
    async getbrandlistfun() {
      const { data } = await GetBraandsList({ size: 1000 })
      this.optionsdata = data.data.list
    },
    // 获取配件
    async getpartslistfun(key) {
      const { data } = await GetPartsList()
      this.partsdata = data.data.list
    },
    // 获取适配型号
    async getequipmentlistfun() {
      const { data } = await GetEquipmentList(this.pageData)
      this.getanpostfun(data, '获取')
      this.tableData = data.data.equipment_Info
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
    },
    // 添加适配型号
    async addequipmentfun(v) {
      const { data } = await AddEquipment(v)
      this.getanpostfun(data, '添加', this.getequipmentlistfun())
    },
    // 删除适配型号
    async delequipmentfun(id) {
      const { data } = await DelEquipment({ id })
      this.getanpostfun(data, '删除', this.getequipmentlistfun())
    },
    // 修改
    async editequipmentfun(ev) {
      const { data } = await EditEquipment(ev)
      this.getanpostfun(data, '修改', this.getequipmentlistfun())
    },
    // 查看
    async oneequipmentfun(id) {
      const { data } = await GetOneEquipment({ id })
      this.dform = data.data.equipment_Info
    },

    // 请求区结束Ψ(￣∀￣)Ψ
    // 公共方法区└(^o^)┘└(^o^)┘
    // 请求后的判断
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        this.handleClose()
        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>
<style lang='less' scoped>
.peitan ::v-deep .el-dialog{
  height: 50%;
  overflow: auto;
}
</style>
